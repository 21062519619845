import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { ApiService } from './api.service';
import { RoleGuardService } from './auth/role-guard.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  currentUser
  constructor(private authService: AuthService, private apiService: ApiService, private roleGuardService: RoleGuardService) { }

  public user
  reverse: boolean = true;
  // isAdmin = false;

  ngOnInit() {
    this.getCurrentUser();
    // this.authService.isAdmin;
  }

  get isAdmin() {
    if (localStorage.getItem('admin') == 'true')
      return true
    // console.log(localStorage.getItem('admin'))
    return false
  }

  getCurrentUser() {
    if (this.authService.isAuthenticated) {
      this.apiService.getCurrentUser().subscribe(
        data => { this.user = data },
        err => console.error(err)
        // () => console.log('app.component', this.user.user.name)
      );
    }
    return false
  }

}