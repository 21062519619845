import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './auth/role-guard.service';
import { NewReportComponent } from './Reports/newReportComponent';
import { RegistrationComponent } from './auth/registration/registration.component';
import { LoginComponent } from './auth/login/login.component';
import { TeamMemberComponent } from './team/teamMember/teammember.component';
import { UsersComponent } from './team/users/users.component';
import { TeamMemberProfileComponent } from './team/teamMemberProfile/teamMemberProfile.component';
import { TeamMemberAddComponent } from './team/teamMemberAdd/teamMemberAdd.component';
import { UserProfileComponent } from './team/userProfile/userProfile.component';
import { MyprofileComponent } from './profile/myProfile/myProfile.component';
import { MyReportComponent } from './Reports/myReports.component';
import { AllReportComponent } from './admin/all-report/all-report.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

const appRoutes: Routes = [
    { path: '', component: NewReportComponent, canActivate: [AuthGuard] },
    { path: 'register', component: RegistrationComponent },
    { path: 'login', component: LoginComponent },
    { path: 'users', component: UsersComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' }},
    { path: 'teammember', component: TeamMemberComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },
    { path: 'teammember-profile/:id', component: TeamMemberProfileComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },
    { path: 'teammember-add', component: TeamMemberAddComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },
    { path: 'profile/:id', component: UserProfileComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },
    { path: 'newreport', component: NewReportComponent, canActivate: [AuthGuard] },
    { path: 'myprofile', component: MyprofileComponent },
    { path: 'myreports', component: MyReportComponent },
    { path: 'allreports', component: AllReportComponent, canActivate: [RoleGuard], data: { expectedRole: 'admin' } },
    { path: 'forgot', component: ForgotPasswordComponent },
    { path: 'reset/:token', component: ResetPasswordComponent },
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);


// const routes: Routes = [
//     { path: '', component: NewReportComponent },
//     { path: 'register', component: RegistrationComponent },
//     { path: 'login', component: LoginComponent },
//     { path: 'users', component: UsersComponent },
//     { path: 'teammember', component: TeamMemberComponent },
//     { path: 'teammember-profile/:id', component: TeamMemberProfileComponent },
//     { path: 'teammember-add', component: TeamMemberAddComponent },
//     { path: 'profile/:id', component: UserProfileComponent },
//     { path: 'newreport', component: NewReportComponent },
//     { path: 'myprofile', component: MyprofileComponent },
//     { path: 'myreports', component: MyReportComponent },
//     { path: 'allreports', component: AllReportComponent },
//     { path: 'forgot', component: ForgotPasswordComponent },
//     { path: 'reset/:token', component: ResetPasswordComponent },
//     { path: '**', redirectTo: '' }
// ];