import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { loginUserData } from "./loginUserData";
import { environment } from "../../environments/environment";
import { DataSharingService } from '../shared/services/dataSharing.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import decode from 'jwt-decode';

@Injectable()
export class AuthService {
  // messages = []
  path = environment.path + '/auth'

  TOKEN_KEY = 'token'
  NAME_KEY = 'name'
  ADMIN = 'administrator'
  errorLogin = false
  errorMail = false
  hideForm = false
  getTokenPass = false
  // ifisAdmin = false

  constructor(private http: HttpClient,
    private router: Router,
    private dataSharingService: DataSharingService,
    public jwtHelper: JwtHelperService) { }

  get token() {
    return localStorage.getItem(this.TOKEN_KEY)
  }

  get isAuthenticated() {
    return !!localStorage.getItem(this.TOKEN_KEY)
  }

  // ifAdmin() {
  //   const expectedRole = 'admin';
  //   const token = localStorage.getItem('token');
  //   // decode the token to get its payload
  //   const tokenPayload = decode(token);
  //   console.log(tokenPayload)
  //   if (tokenPayload.role == expectedRole) {
  //     console.log('tak')
  //     this.ifisAdmin = true;
  //     return true;
  //   } else {
  //     console.log('nie')
  //     return false;
  //   }
  // }

  get name() {
    return localStorage.getItem(this.NAME_KEY);
  }

  // get isAdmin() {
  //   if (localStorage.getItem('admin') == 'true')
  //     return true
  //   console.log(localStorage.getItem('administrator'))
  //   return false
  // }

  logout() {
    localStorage.removeItem(this.TOKEN_KEY)
    localStorage.removeItem('currentUser');
    localStorage.removeItem('admin');
    localStorage.removeItem('name');
    this.router.navigate(['/login']);
    // this.ifisAdmin = false;
  }

  registerUser(registerData) {
    this.http.post<any>(this.path + '/register', registerData).subscribe(res => {
      this.saveToken(res.token)
      this.router.navigate(['/newreport']);
    })
  }

  loginUser(loginData) {
    this.http.post<loginUserData>(this.path + '/login', loginData).subscribe(res => {
      this.errorLogin = false
      // localStorage.setItem('administrator', res.admin)
      localStorage.setItem(this.NAME_KEY, res.user.name)
      this.saveToken(res.token)
      this.dataSharingService.isUserLoggedIn.next(true);
      console.log(res.user.name)
      this.router.navigate(['/'])
      const token = localStorage.getItem('token');
      const tokenPayload = decode(token);
      console.log(tokenPayload)
      if (tokenPayload.role == 'admin') {
        localStorage.setItem('admin', 'true')
        // this.ifisAdmin = true;
      } 
      // else {
        // this.ifisAdmin = false;
      // }

    }, error => {
      this.errorLogin = true
      console.log('errorrrrr1', error)
    })
  }

  forgotPassword(forgotPasswordData) {
    this.http.post(this.path + '/forgot', forgotPasswordData).subscribe(res => {
      console.log('res', res)
      this.errorMail = false
      this.hideForm = true
      console.log('this.errorMail', this.errorMail);
      // this.router.navigate(['/newreport'])

    }, error => {
      this.errorMail = true
      this.hideForm = false
      console.log('errorrrrr2', error)
    })
  }

  resetPassword(token, resetPasswordData) {
    this.http.post(this.path + '/reset/' + token, resetPasswordData).subscribe(res => {
      console.log('res', res)
      this.hideForm = true
    }, error => { this.errorMail = true })
  }

  getToken(token) {
    return this.http.get(this.path + '/reset/' + token).subscribe(res => {
      console.log('res', res)
      this.getTokenPass = true
    }, error => { console.log('errorrrrr4', error) })
  }

  saveToken(token) {
    localStorage.setItem(this.TOKEN_KEY, token)
  }

}
