import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.less']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordData: FormGroup;
  password: FormControl;
  confirm: FormControl;

  constructor(private authService: AuthService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.getResetToken();
  }

  createFormControls() {
    this.password = new FormControl("", [
      Validators.minLength(8),
      Validators.required
    ]);
    this.confirm = new FormControl("", [
      Validators.minLength(8),
      Validators.required
    ]);
  }

  createForm() {
    this.resetPasswordData = new FormGroup({
      password: this.password,
      confirm: this.confirm,
    });
  }

  getResetToken() {
    var token = this.route.snapshot.params.token;
    console.log('token in front', token)
    this.authService.getToken(token);
  }

  onSubmit() {
    if(this.resetPasswordData.valid && this.resetPasswordData.value.password === this.resetPasswordData.value.confirm) {
      var token = this.route.snapshot.params.token
      this.authService.resetPassword(token, this.resetPasswordData.value)
      console.log(this.resetPasswordData.value);
    }
  }

}
