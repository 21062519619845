import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment.prod";
import { Router } from "@angular/router";
// import * as $ from 'jquery';
import { DataSharingService } from './shared/services/dataSharing.service';
declare var $:any;

@Injectable()
export class ApiService {
    messages: any = []
    users: any = []
    workers: any = []
    profile: any = []
    currentUser = {}
    path = environment.path

    constructor(private http: HttpClient, private router: Router, private dataSharingService: DataSharingService) { }

    postMessages(message) {
        this.http.post(this.path + '/post', message, { responseType: 'text' }).subscribe(res => {
        })
    }

    getReports(userId) {
        return this.http.get<any>(this.path + '/reports/' + userId)
    }

    getAllReports() {
        return this.http.get<any>(this.path + '/getAllReports')
    }

    deleteReport(IdReport) {
        return this.http.delete(this.path + '/deleteReport/' + IdReport)
    }

    deletePhoto(reportId, photoId) {
        return this.http.put(this.path + '/deletePhoto/' + reportId, {photoId:photoId})
    }

    addPhotoReport(reportIds, formData) {
        return this.http.put(this.path + '/addPhotoReport/' + reportIds, formData)
    }

    addWorker(newWorkersData) {
        return this.http.post<any>(this.path + '/newWorker', newWorkersData)
    }

    getWorkers() {
        return this.http.get<any>(this.path + '/getworkers')
        // .subscribe(res => {
        //     this.workers = res;
        //     console.log(res)
        //     if (res == 'login') {
        //         this.router.navigate(['/login']);
        //     }
        // })
    }

    getWorker(id) {
        return this.http.get(this.path + '/getworker/' + id)
    }

    postNewReportData(newReportData) {
        this.http.post<any>(this.path + '/upload', newReportData).subscribe(res => {
            this.router.navigate(['/myreports']);
            this.dataSharingService.isUserLoggedIn.next(true);
            if (res == 'login') {
                this.router.navigate(['/login']);
            }
        })
    }

    updateNewReportAdmin(author, authId, reportId, status, date, principal, startTime, endTime, address, typeReport, description, team, payoffType) {
        this.http.put<any>(this.path + '/uploadAdmin', {
            author: author,
            reportId: reportId,
            status: status,
            date: date,
            authId: authId,
            principal: principal,
            startTime: startTime,
            endTime: endTime,
            address: address,
            typeReport: typeReport,
            description: description,
            team: team,
            payoffType: payoffType
        }).subscribe(res => {
            console.log('put', res)
        })
    }

    updateReporData(reportId, newReportData) {
        return this.http.put<any>(this.path + '/updateReporData/'+ reportId, {newReportData})
    }

    updateUserProfile(updateUserProfileData) {
        this.http.put<any>(this.path + '/updateUserProfile', updateUserProfileData).subscribe(res => {
            // console.log('profile data updated', res)
            $('.toggle').toggleClass('display displayNone');
            this.dataSharingService.dataUpdated.next(true);
        }, error => {
            console.log('errorrrrr1', error)
          })
    }

    updateWorkerProfile(updateWorkerProfileData) {
        return this.http.put<any>(this.path + '/updateWorkerProfile', updateWorkerProfileData)
    }

    getUsers() {
        return this.http.get(this.path + '/getusers')
    }

    deleteUser(id) {
        return this.http.delete(this.path + '/deleteuser/' + id).subscribe(res => {
            if (res == 'removed') {
                this.router.navigate(['../../users']);
            }
        })
    }

    deleteWorker(id) {
        return this.http.delete(this.path + '/deleteworker/' + id).subscribe(res => {
            if (res == 'removed') {
                this.router.navigate(['../../teammember']);
            }
        })
    }

    getProfile(id) {
        return this.http.get(this.path + '/profile/' + id)
    }

    getCurrentUser() {
        return this.http.get<any>(this.path + '/curruser')
    }

    isLoggedIn() {
        return this.getCurrentUser
    }
}