import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { AuthService } from '../auth/auth.service';
import { DataSharingService } from '../shared/services/dataSharing.service';
import { SubjectService } from '../shared/services/subject.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  constructor(private apiService: ApiService,
    private authService: AuthService,
    private dataSharingService: DataSharingService,
    private mySubjectService: SubjectService,
    private router: Router
  ) {
    this.dataSharingService.isUserLoggedIn.subscribe(value => {
      this.isUserLoggedIn = value;
      console.log(this.isUserLoggedIn)
      this.getCurrentUser()
    });
  }

  public user
  acceptReports
  rejectReports
  newReports
  aftermap: any
  authStatus
  isUserLoggedIn: boolean;
  term

  ngOnInit() {
    this.getCurrentUser()
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().subscribe(
      data => {
        this.user = data,
        this.aftermap = data.myreport.map(function (p) {
          return p.reportData;
        }).reduce(function (a, b) {
          return a.concat(b);
        }).map(function (c) {
          return c.status;
        }),
        this.newReports = this.aftermap.filter(x => x == 'new'),
        this.acceptReports = this.aftermap.filter(x => x == 'approve'),
        this.rejectReports = this.aftermap.filter(x => x == 'reject');
      },
      err => console.error(err)
      //() => console.log('newReports', this.newReports.length, this.acceptReports.length, this.aftermap)
    );
  }

  myReportsFilter(filterStatu) {
    this.router.navigate(['/myreports']).then(() => {
      this.mySubjectService.mysubject.next(filterStatu);
      console.log('myReportsFilter run');
    });
  }


}
