import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from '@angular/router';

@Component({
  selector: 'app-team-member-add',
  templateUrl: './teamMemberAdd.component.html',
  styleUrls: ['./teamMemberAdd.component.less']
})
export class TeamMemberAddComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router) { }

  newWorkerData: FormGroup;
  name: FormControl;
  surname: FormControl;
  email: FormControl;
  phone: FormControl;
  dateofbirth: FormControl;
  address: FormControl;
  description: FormControl;
  form

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.name = new FormControl("", Validators.required);
    this.surname = new FormControl("", Validators.required);
    this.email = new FormControl("", Validators.required);
    this.phone = new FormControl("", Validators.required);
    this.dateofbirth = new FormControl("");
    this.address = new FormControl("");
    this.description = new FormControl("");
  }

  createForm() {
    this.newWorkerData = new FormGroup({
      name: this.name,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
      dateofbirth: this.dateofbirth,
      address: this.address,
      description: this.description
    });
  }

  addWorker() {
    this.apiService.addWorker(this.newWorkerData.value).subscribe(res => {
      if (res == 'saved') {
        this.router.navigate(['/teammember']);
      }
      this.apiService.getWorkers();
    }, error => {
      console.log('error', error)
    });

  }

}
