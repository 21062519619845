import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable()
export class PrintService {

  constructor() { }

  print(event): void {
    let x, incrementAndData, printContents, popupWin, status, date, principal, startTime, endTime, address, typeReport, description, team, payoffType;
    printContents = document.getElementById(event);    
    incrementAndData = printContents.getElementsByClassName('incrementAndData')[0].innerHTML;
    status = printContents.getElementsByClassName('status')[0].innerHTML;
    date = printContents.getElementsByClassName('date')[0].innerHTML;
    principal = printContents.getElementsByClassName('principal')[0].innerHTML;
    startTime = printContents.getElementsByClassName('startTime')[0].innerHTML;
    endTime = printContents.getElementsByClassName('endTime')[0].innerHTML;
    address = printContents.getElementsByClassName('address')[0].innerHTML;
    typeReport = printContents.getElementsByClassName('typeReport')[0].innerHTML;
    description = printContents.getElementsByClassName('description')[0].innerHTML;
    team = printContents.getElementsByClassName('team')[0].innerHTML;
    payoffType = printContents.getElementsByClassName('payoffType')[0].innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Raport</title>
          <style>
          @media print {
            .noprint {display:none !important;}
          }
          table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }
        th, td {
            padding: 5px;
            text-align: left;    
        }
          </style>
        </head>
    <body onload="window.print();window.close()">

    <table style="width:100%">
      <tr>
        <th>Numer:</th>
        <td>${incrementAndData}</td>
      </tr>
      <tr>
        <th>Status:</th>
        <td>${status}</td>
      </tr>
      <tr>
        <th>Data:</th>
        <td>${date}</td>
      </tr>
      <tr>
        <th>Zleceniodawca:</th>
        <td>${principal}</td>
      </tr>
      <tr>
        <th>Godzina rozpoczęcia:</th>
        <td>${startTime}</td>
      </tr>
      <tr>
        <th>Godzina zakończenia:</th>
        <td>${endTime}</td>
      </tr>
      <tr>
        <th>Adres:</th>
        <td>${address}</td>
      </tr>
      <tr>
        <th>Rodzaj zgłoszenia:</th>
        <td>${typeReport}</td>
      </tr>
      <tr>
        <th>Opis szczegółowy:</th>
        <td>${description}</td>
      </tr>
      <tr>
        <th>Skład zespołu:</th>
        <td>${team}</td>
      </tr>
      <tr>
        <th>Forma rozliczenia:</th>
        <td>${payoffType}</td>
      </tr>
    </table>
    <br>
    <p>Podpis  _________________</p>
    </body>
      </html>`
    );
    popupWin.document.close();
  }
}
