import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'app-team',
  templateUrl: './teammember.component.html',
  styleUrls: ['./teammember.component.less']
})
export class TeamMemberComponent implements OnInit {

  constructor(private apiService: ApiService) { }
  panelOpenState: boolean = false;
  registerData = {}
  form: any = {}
  workers
  worker

  ngOnInit() {
    this.getWorkers();
    // this.getWorker();
  }

  getWorkers() {
    this.apiService.getWorkers().subscribe(res => {
      this.workers = res;
      console.log(res)
    })
  }

  // getWorker() {
  //   this.apiService.getWorker(this.workers._id).subscribe(
  //     data => {
  //       this.worker = data,
  //         console.log(this.worker)
  //     },
  //     err => console.error(err)
  //   );
  // }




}
