import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
import { Lightbox } from 'angular2-lightbox';
import { environment } from "../../../environments/environment.prod";
import { PrintService } from '../../shared/services/print.service';
import { SubjectService } from '../../shared/services/subject.service';
import { DataSharingService } from '../../shared/services/dataSharing.service';
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
declare var $: any;

@Component({
  selector: 'all-reports',
  templateUrl: './all-report.component.html',
  styleUrls: ['../../Reports/myReportscomponent.less']

})
export class AllReportComponent {
  constructor(private apiService: ApiService,
    private route: ActivatedRoute,
    private printService: PrintService,
    private mySubjectService: SubjectService,
    private router: Router,
    private dataSharingService: DataSharingService
  ) {

  }
  public user
  public reports
  public data
  getToAllReports
  path = environment.path
  // status
  users
  term
  items = []
  workerss
  workers = []
  reportEdit
  form: any = {}
  files: any = []
  readyFile
  updateReportData: FormGroup
  status: FormControl
  date: FormControl
  principal: FormControl
  startTime: FormControl
  endTime: FormControl
  address: FormControl
  typeReport: FormControl
  description: FormControl
  team: FormControl
  payoffType: FormControl
  horizontalView = false
  selectedOptions = ''

  statuses = [
    { value: 'new', text: 'Nowy' },
    { value: 'approve', text: 'Zaakceptowany' },
    { value: 'reject', text: 'Odrzucony' }
  ];

  payoffTypes = [
    { value: 'cash', text: 'Gotówka' },
    { value: 'transfer', text: 'Przelew' },
    { value: 'other', text: 'Inne' }
  ];

  public uploader: FileUploader = new FileUploader({
    url: 'any',
    disableMultipart: false,
    autoUpload: false,
    allowedFileType: ['image', 'pdf']
  });

  ngOnInit() {
    this.allReports()
    this.getCurrentUser();
    this.createFormControls();
    this.createForm();
    this.getToAllUsers();
    this.filterInit();
    this.getWorkers();
  }

  filterInit() {
    this.mySubjectService.mysubject.subscribe((value) => {
      this.term = value;
      this.items = [value];
    });
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().subscribe(
      data => {
        this.reports = data.myreport,
          this.data = data
      },
      err => console.error(err),
      // () => console.log('done loading current user', this.payoffTypeValue)

    )
  }

  getWorkers() {
    this.apiService.getWorkers().subscribe(res => {
      this.workers = res;
      console.log(res)
    })
  }

  getToAllUsers() {
    this.apiService.getUsers().subscribe(res => {
      this.users = res;
    })
  }

  changeTableView() {
    this.horizontalView = !this.horizontalView
  }

  allReports() {
    this.apiService.getAllReports().subscribe(res => {
      this.getToAllReports = res;
      // if(res == 'login') {
      //     this.router.navigate(['/login']);
      // }
    })
  }

  createFormControls() {
    this.status = new FormControl();
    this.date = new FormControl("", Validators.required);
    this.principal = new FormControl("", Validators.required);
    this.startTime = new FormControl();
    this.endTime = new FormControl("", Validators.required);
    this.address = new FormControl("");
    this.typeReport = new FormControl("");
    this.description = new FormControl("");
    this.team = new FormControl("");
    this.payoffType = new FormControl("");
  }

  createForm() {
    this.updateReportData = new FormGroup({
      status: this.status,
      date: this.date,
      principal: this.principal,
      startTime: this.startTime,
      endTime: this.endTime,
      address: this.address,
      typeReport: this.typeReport,
      description: this.description,
      team: this.team,
      payoffType: this.payoffType
    });
  }

  onAdd($event) {
    this.term = $event.value
  }

  deleteReport(IdReport) {
    if (confirm("Are you sure to delete?")) {
      this.apiService.deleteReport(IdReport).subscribe(
        (success) => {
          this.allReports();
          this.dataSharingService.isUserLoggedIn.next(true);
        },
        (err) => console.log('Debug:deletefailed', err)
      );
    }
  }

  //sorting
  key: string = 'data'; //set default
  reverse: boolean = true;
  sort(key) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  editReport($event, report) {
    this.reportEdit = report
    // console.log($event, report)
  }

  fotoRemove(index, event, fotoId) {
    // console.log(fotoId)

    if (confirm("Are you sure to delete?")) {
      this.apiService.deletePhoto(this.reportEdit._id, fotoId).subscribe(
        (success) => {
          this.reportEdit.files.splice(index, 1);
        },
        (err) => console.log('Debug:deletefailed', err)
      );
      console.log('delete', this.reportEdit._id, fotoId)
    }
  }

  addPhoto(event) {
    let target = event.target || event.srcElement;
    this.files.push(target.files);
    // console.log(this.files);
  }

  updateReport() {
    this.apiService.updateReporData(this.reportEdit._id, this.updateReportData.value).subscribe(res => {
      // console.log('put', res)
      $('#saveSuccess').modal('show');
      $('#editData').modal('hide');
      this.allReports();
    }, error => {
      console.log('error', error)
      $('#saveError').modal('show');
    })
    if (this.files.length > 0) {
      // console.log(this.files)
      let filess: FileList = this.files;
      const formData = new FormData();
      for (let i = 0; i < filess.length; i++) {
        this.readyFile = filess[i];
        formData.append('file', this.readyFile[0]);
      }
      this.apiService.addPhotoReport(this.reportEdit._id, formData).subscribe(
        (success) => {
          this.allReports();
        },
        (err) => console.log('Debug:deletefailed', err)
      )

    }
  }

  //initializing p to one
  p: number = 1;

  print($event) {
    this.printService.print($event);
  }

}