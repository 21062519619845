import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from 'ng2-file-upload';
import { LightboxModule } from 'angular2-lightbox';
import { NgxGalleryModule } from 'ngx-gallery';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderModule } from 'ngx-order-pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import * as $ from 'jquery';

import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { ApiService } from './api.service';
import { MyprofileComponent } from './profile/myProfile/myProfile.component';

// import { WorkersComponent } from './team/workers/workers.component';
import { UsersComponent } from './team/users/users.component';
import { TeamMemberComponent } from './team/teamMember/teammember.component';
import { UserProfileComponent } from './team/userProfile/userProfile.component';

import { AllReportComponent } from './admin/all-report/all-report.component';

import { FilterPipe }from './Reports/filter.pipe';
import { MyFilterPipe } from './shared/pipes/filterArray.pipe';
import { NewReportComponent } from './Reports/newReportComponent';
import { MyReportComponent } from './Reports/myReports.component';

import { RegistrationComponent } from './auth/registration/registration.component';
import { AuthInterceptorService } from './auth/authInterceptor.service';
import {LoginComponent} from "./auth/login/login.component";
import { AuthService } from './auth/auth.service';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

import { PrintService } from './shared/services/print.service';
import { HeaderComponent } from './header/header.component';
import { DataSharingService } from './shared/services/dataSharing.service';
import { SubjectService } from './shared/services/subject.service';
import { TagInputModule } from 'ngx-chips';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ReportEditComponent } from './Reports/reportEdit/report-edit.component';
import { TeamMemberProfileComponent } from './team/teamMemberProfile/teamMemberProfile.component';
import { TeamMemberAddComponent } from './team/teamMemberAdd/teamMemberAdd.component';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuardService } from './auth/auth-guard.service';
import { RoleGuardService } from './auth/role-guard.service';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    RegistrationComponent,
    LoginComponent,
    UsersComponent,
    NewReportComponent,
    MyReportComponent,
    MyprofileComponent,
    FilterPipe,
    MyFilterPipe,
    TeamMemberComponent,
    AllReportComponent,
    RegistrationComponent,
    UserProfileComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    HeaderComponent,
    ReportEditComponent,
    TeamMemberProfileComponent,
    TeamMemberAddComponent
    // WorkersComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    routing,
    FormsModule,
    ReactiveFormsModule,
    // RouterModule.forRoot(routes),
    BrowserAnimationsModule,
    LightboxModule,
    NgxGalleryModule,
    Ng2SearchPipeModule,
    OrderModule,
    NgxPaginationModule,
    TagInputModule,
    NgbModule.forRoot(),
    FileUploadModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['example.com'],
        blacklistedRoutes: ['example.com/examplebadroute/']
      }
    })
  ],
  providers: [
    ApiService, 
    AuthService, 
    PrintService, 
    DataSharingService, 
    SubjectService, 
    AuthGuardService,
    RoleGuardService, { 
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }