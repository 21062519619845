import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
import { environment } from "../../../environments/environment.prod";
import { PrintService } from '../../shared/services/print.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FileUploader } from 'ng2-file-upload';
import { SubjectService } from '../../shared/services/subject.service';
import { DataSharingService } from '../../shared/services/dataSharing.service';
declare var $: any;

@Component({
  selector: 'app-team-member-profile',
  templateUrl: './teamMemberProfile.component.html',
  styleUrls: ['./teamMemberProfile.component.less']
})
export class TeamMemberProfileComponent implements OnInit {

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private printService: PrintService,
    private mySubjectService: SubjectService,
    private dataSharingService: DataSharingService
  ) { }

  items
  term
  user
  userId
  public lastOfWork;
  userReports
  reports
  updateWorkerProfileData: FormGroup;
  name: FormControl;
  surname: FormControl;
  email: FormControl;
  phone: FormControl;
  dateofbirth: FormControl;
  address: FormControl;
  description: FormControl;



  ngOnInit() {
    this.userId = this.route.snapshot.params.id
    this.getWorker();
    this.createFormControls();
    this.createForm();
  }

  getWorker() {
    this.apiService.getWorker(this.userId).subscribe(
      data => {
        this.user = data,
          console.log(this.user)
      },
      err => console.error(err)
    );
  }


  createFormControls() {
    this.name = new FormControl("", Validators.required);
    this.surname = new FormControl("", Validators.required);
    this.email = new FormControl({ disabled: true });
    this.phone = new FormControl("", Validators.required);
    this.dateofbirth = new FormControl("");
    this.address = new FormControl("");
    this.description = new FormControl("");
  }

  createForm() {
    this.updateWorkerProfileData = new FormGroup({
      name: this.name,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
      dateofbirth: this.dateofbirth,
      address: this.address,
      description: this.description
    });
  }

  onSubmit() {
    if (this.updateWorkerProfileData.valid) {
      this.apiService.updateWorkerProfile(this.updateWorkerProfileData.value).subscribe(res => {
        this.getWorker();
        $('.toggle').toggleClass('display displayNone');
        // this.dataSharingService.dataUpdated.next(true);
      }, error => {
        console.log('error', error)
      })

    }
  }

  editProfile() {
    $('.toggle').toggleClass('display displayNone');
  }

  deleteWorker() {
    if (confirm("Are you sure to delete?")) {
      this.apiService.deleteWorker(this.userId);
    }
  }



}