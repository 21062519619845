import { Component, EventEmitter, OnInit, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { DataSharingService } from '../shared/services/dataSharing.service';
import { FileUploader, FileLikeObject } from 'ng2-file-upload';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder } from "@angular/forms";


@Component({
  selector: 'newReport',
  templateUrl: 'newReport.component.html',
  styleUrls: ['./newReport.component.less']
})
export class NewReportComponent {
  constructor(private http: HttpClient, private apiService: ApiService,
    private dataSharingService: DataSharingService) {
    this.form = {}
  }
  form: any = {}
  files: any = []
  public user
  readyFile
  workers

  newReportData: FormGroup;
  date: FormControl;
  principal: FormControl;
  startTime: FormControl;
  endTime: FormControl;
  address: FormControl;
  typeReport: FormControl;
  description: FormControl;
  team: FormControl;
  payoffType: FormControl;

  payoffTypes = [
    { value: 'cash', text: 'Gotówka' },
    { value: 'transfer', text: 'Przelew' },
    { value: 'other', text: 'Inne' }
  ];

  public uploader: FileUploader = new FileUploader({
    url: 'any',
    disableMultipart: false,
    autoUpload: false,
    allowedFileType: ['image', 'pdf']
  });

  createFormControls() {
    this.date = new FormControl("", Validators.required);
    this.principal = new FormControl("", Validators.required);
    this.startTime = new FormControl("");
    this.endTime = new FormControl("");
    this.address = new FormControl("", Validators.required);
    this.typeReport = new FormControl("", Validators.required);
    this.description = new FormControl("");
    this.team = new FormControl("");
    this.payoffType = new FormControl("", Validators.required);
  }

  createForm() {
    this.newReportData = new FormGroup({
      date: this.date,
      principal: this.principal,
      startTime: this.startTime,
      endTime: this.endTime,
      address: this.address,
      typeReport: this.typeReport,
      description: this.description,
      team: this.team,
      payoffType: this.payoffType
    });
  }

  ngOnInit() {
    this.getCurrentUser();
    this.getWorkers();
    this.createFormControls();
    this.createForm();
  }

  addPhoto(event) {
    let target = event.target || event.srcElement;
    this.files.push(target.files);
    // console.log(this.files);
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().subscribe(
      data => { this.user = data.user },
      err => console.error(err)
      // () => console.log('done loading current user - new report', this.user)
    );
  }

  getWorkers() {
    this.apiService.getWorkers().subscribe(res => {
      this.workers = res;
      console.log(res)
    })
  }

  post() {
    let newReportData;
    if (this.files) {
      let filess: FileList = this.files;
      const formData = new FormData();
      for (let i = 0; i < filess.length; i++) {
        this.readyFile = filess[i];
        formData.append('file', this.readyFile[0]);
      }
      for (var key in this.newReportData.value) {
        formData.append(key, this.newReportData.value[key]);
      }
 
      newReportData = formData;
    } else {
      newReportData = this.form;
    }
    this.apiService.postNewReportData(newReportData)
  }

}