import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  loginData: FormGroup;
  email: FormControl;
  pwd: FormControl;

  public userek
  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.email = new FormControl("", [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]);
    this.pwd = new FormControl("", [
      Validators.required
    ]);
  }

  createForm() {
    this.loginData = new FormGroup({
      email: this.email,
      pwd: this.pwd
    });
  }

  onSubmit() {
    if(this.loginData.valid) {
      this.authService.loginUser(this.loginData.value)
      console.log(this.loginData.value);
    }
  }

  // post() {
  //   // console.log(this.loginData);
  //   this.authService.loginUser(this.loginData)
  //   this.userek = sessionStorage.getItem('loginData')
  //   console.log('userek', this.userek)
  // }

}
