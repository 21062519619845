import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], term): any {
      // console.log('term', term);
    
      return term 
          ? items.filter(item => 
          item.reportData[0].status.toLowerCase().indexOf(term) !== -1 || 
          item.reportData[0].date.indexOf(term) !== -1 ||
          item.reportData[0].team.indexOf(term) !== -1 ||
          item.author.indexOf(term) !== -1)
          : items;
  }
}