import { Component, OnInit } from '@angular/core';
import { ApiService } from "../../api.service";
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import * as $ from 'jquery';
import { DataSharingService } from '../../shared/services/dataSharing.service';

@Component({
  selector: 'login',
  templateUrl: 'myProfile.component.html',
  styleUrls: ['myProfile.component.less']
})
export class MyprofileComponent implements OnInit{
  constructor(private apiService: ApiService, private route: ActivatedRoute, private dataSharingService: DataSharingService, private authService: AuthService) { 
    this.dataSharingService.dataUpdated.subscribe(value => {
      this.dataUpdated = value;
      console.log(this.dataUpdated)
      this.getCurrentUser()
    });
  }

  public user;
  public userReports;
  public lastOfWork;
  dataUpdated: boolean;
  updateUserProfileData: FormGroup;
  name: FormControl;
  surname: FormControl;
  email: FormControl;
  phone: FormControl;
  dateofbirth: FormControl;
  address: FormControl;
  description: FormControl;


  ngOnInit() {
    this.getCurrentUser();
    this.createFormControls();
    this.createForm();
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().subscribe(
      data => { 
        this.user = data.user,
        this.userReports = data.myreport,
        this.lastOfWork = data.myreport[data.myreport.length-1].reportData[0].date,
        console.log(this.lastOfWork)
        },
      err => console.error(err)
    );
  }

  createFormControls() {
    this.name = new FormControl("", Validators.required);
    this.surname = new FormControl("", Validators.required);
    this.email = new FormControl({disabled: true});
    this.phone = new FormControl("", Validators.required);
    this.dateofbirth = new FormControl("");
    this.address = new FormControl("");
    this.description = new FormControl("");
  }

  createForm() {
    this.updateUserProfileData = new FormGroup({
      name: this.name,
      surname: this.surname,
      email: this.email,
      phone: this.phone,
      dateofbirth: this.dateofbirth,
      address: this.address,
      description: this.description
    });
  }

  onSubmit() {
    if (this.updateUserProfileData.valid) {
      this.apiService.updateUserProfile(this.updateUserProfileData.value)
      console.log(this.updateUserProfileData.value);
    }
  }

  editProfile() {
    $('.toggle').toggleClass('display displayNone');
  }

}
