import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.less']
})
export class RegistrationComponent implements OnInit {

  registerData: FormGroup;
  name: FormControl;
  surname: FormControl;
  email: FormControl;
  pwd: FormControl;
  pwdconfirm: FormControl;
  phone: FormControl;
  dateofbirth: FormControl;
  address: FormControl;
  description: FormControl;


  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.name = new FormControl("", Validators.required);
    this.surname = new FormControl("", Validators.required);
    this.email = new FormControl("", [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]);
    this.pwd = new FormControl("", [
      Validators.minLength(8),
      Validators.required
    ]);
    this.pwdconfirm = new FormControl("", [
      Validators.minLength(8),
      Validators.required,
      this.passwordConfirmValidator
    ]);
    this.phone = new FormControl("", Validators.required);
    this.dateofbirth = new FormControl("");
    this.address = new FormControl("");
    this.description = new FormControl("");
  }

  passwordConfirmValidator(group: FormGroup) {
    if (group.value === group.root.value.pwd) {
      console.log('ok', group.root.value.pwd, group.root.value.pwdconfirm )
      return  null
    } else {
      console.log('error')
      return  { error: true }
    }
  }

  createForm() {
    this.registerData = new FormGroup({
      name: this.name,
      surname: this.surname,
      email: this.email,
      // password: new FormGroup({
        pwd: this.pwd,
        pwdconfirm: this.pwdconfirm,
      // }),
      phone: this.phone,
      dateofbirth: this.dateofbirth,
      address: this.address,
      description: this.description
    });
  }

  onSubmit() {
    if (this.registerData.valid) {
      this.authService.registerUser(this.registerData.value)
      console.log(this.registerData.value);
    }
  }

  // post() {
  //   console.log(this.registerData);
  //   this.authService.registerUser(this.registerData)
  // }


}