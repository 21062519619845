import { Component } from '@angular/core';
import { ApiService } from '../../api.service';

@Component({
  selector: 'users',
  templateUrl: './users.component.html'
})
export class UsersComponent {
  constructor(private apiService: ApiService) {}

  users

  ngOnInit() {
    this.apiService.getUsers();
    // this.apiService.getCurrentUser()
    this.getToAllUsers()
  }

  getToAllUsers() {
    this.apiService.getUsers().subscribe(res => {
      this.users = res;
    })
  }
}