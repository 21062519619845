import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
import { environment } from "../../../environments/environment.prod";
import { PrintService } from '../../shared/services/print.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FileUploader } from 'ng2-file-upload';
import { SubjectService } from '../../shared/services/subject.service';
import { DataSharingService } from '../../shared/services/dataSharing.service';
declare var $:any;

@Component({
  selector: 'app-userProfile',
  templateUrl: './userProfile.component.html',
  styleUrls: ['./userProfile.component.less']
})
export class UserProfileComponent implements OnInit {

  constructor(
    private apiService: ApiService, 
    private route: ActivatedRoute, 
    private printService: PrintService,
    private mySubjectService: SubjectService,
    private dataSharingService: DataSharingService
    ) {
    this.mySubjectService.mysubject.subscribe((value) => {
      this.term = value;
      this.items = [value];
      console.log(this.term)
    });
  }
  
  items
  term
  user
  userId
  public lastOfWork;
  userReports
  reports



  ngOnInit() {
    this.userId = this.route.snapshot.params.id
    this.apiService.getProfile(this.userId).subscribe(
      data => {
        this.user = data,
        console.log(this.user)
      },
      err => console.error(err)
      );
    this.getReports();
  }

  getReports() {
    this.apiService.getReports(this.userId)
    .subscribe(
      data => { 
        this.reports = data,
        console.log(data)
        this.lastOfWork = (data.length > 0) ? data[data.length-1].reportData[0].date : ''
        },
      err => console.error(err)
    );
  }

  deleteUser() {
    if (confirm("Are you sure to delete?")) {
      this.apiService.deleteUser(this.userId);
    }
  }

 

}