import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-report-edit',
  templateUrl: './report-edit.component.html',
  styleUrls: ['./report-edit.component.less']
})
export class ReportEditComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  reportEdit
  reports
  data
  updateReportData: FormGroup
  date: FormControl
  principal: FormControl
  startTime: FormControl
  endTime: FormControl
  address: FormControl
  typeReport: FormControl
  description: FormControl
  team: FormControl
  payoffTypeName: FormControl

  ngOnInit() {
    this.getCurrentUser();
    this.createFormControls();
    this.createForm();
    this.apiService.getWorkers();
  }

  getCurrentUser() {
    this.apiService.getCurrentUser().subscribe(
      data => {
        this.reports = data.myreport,
        this.data = data
      },
      err => console.error(err)
      // () => console.log('report-edit', this.reportEdit.reportData)

    )
  }

  editReport($event, report) {
    this.reportEdit = report
    console.log($event, report)
  }

  createFormControls() {
    this.date = new FormControl("", Validators.required);
    this.principal = new FormControl("", Validators.required);
    this.startTime = new FormControl();
    this.endTime = new FormControl("", Validators.required);
    this.address = new FormControl("");
    this.typeReport = new FormControl("");
    this.description = new FormControl("");
    this.team = new FormControl("");
    this.payoffTypeName = new FormControl("");
  }

  createForm() {
    this.updateReportData = new FormGroup({
      date: this.date,
      principal: this.principal,
      startTime: this.startTime,
      endTime: this.endTime,
      address: this.address,
      typeReport: this.typeReport,
      description: this.description,
      team: this.team,
      payoffTypeName: this.payoffTypeName
    });
  }

  updateReport() {
    console.log()
    // this.apiService.postNewReportData(this.form)
  }

}
