import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormGroup, FormControl, Validators } from "@angular/forms";

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordData: FormGroup;
  email: FormControl;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.email = new FormControl("", [
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")
    ]);
  }

  createForm() {
    this.forgotPasswordData = new FormGroup({
      email: this.email
    });
  }

  onSubmit() {
    if(this.forgotPasswordData.valid) {
      this.authService.forgotPassword(this.forgotPasswordData.value)
      console.log(this.forgotPasswordData.value);
    }
  }

}
